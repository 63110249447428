import Vue from 'vue'
import Router from 'vue-router'
import store from "@/store"

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: "/",
  routes: [
    {
      name: 'ホーム',
      path: '/home',
      component: () => import('@/views/Home'),
      meta: { requiresAuth: true }
    },
    {
      name: 'ログイン',
      path: '/signin',
      component: () => import('@/views/SignIn'),
    },
    {
      name: 'ログイン処理中',
      path: '/signin_processing',
      component: () => import('@/views/SignInProcessing'),
    },
    {
      name: 'アカウント',
      path: '/account',
      component: () => import('@/views/Account'),
      meta: { requiresAuth: true }
    },
    {
      name: '地図',
      path: '/map',
      component: () => import('@/views/Map'),
      meta: { requiresAuth: true }
    },
    {
      name: 'データ一覧',
      path: '/table',
      component: () => import('@/views/Table'),
      meta: { requiresAuth: true }
    },
    {
      name: 'プライバシーポリシー',
      path: '/privacy_policy',
      component: () => import('@/views/PrivacyPolicy'),
      meta: { requiresAuth: false }
    },
    {
      name: '使い方',
      path: '/how_to_use',
      component: () => import('@/views/HowToUse'),
      meta: { requiresAuth: false }
    },
    {
      name: 'LINE Editionの使い方',
      path: '/how_to_line',
      component: () => import('@/views/HowToLine'),
      meta: { requiresAuth: false }
    },

  ],
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth) && !store.state.auth.lineUser && process.env.VUE_APP_DEPLOY_MODE == "PROD") {
    // next({ path: '/singin', query: { redirect: to.fullPath } });
    next({ path: '/signin' });
  // } else if (to.matched.some(record => record.meta.requiresAdmin) && store.state.auth.superUser == false) {
  //   next(false); //ルートにリダイレクト
  // } else if (to.matched.some(record => record.meta.login) && store.state.auth.userToken) {
  //   next({ path: '/user'})
  } else {
    next();
  }
});


export default router

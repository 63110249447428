export default {
    state: {
      drawer: null,
    },
    mutations: {
      SET_DRAWER (state, payload) {
        state.drawer = payload
      },
    },
    actions: {
  
    },
  }
  
export default {
  state: {
    user: null,
    lineUser: null,
    lineAccessToken: null,
    lineRefreshToken: null,
    tokenExpiration: null,
    handleName: null
  },
  mutations: {
    onAuthEmailChanged(state, email) {
      state.email = email;
    },
    onSignedIn(state, user) {
      state.user = user
    },
    onSignedOut(state) {
      state.user = null
    },
    onSignedInWithLine(state, data) {
      state.lineUser = data.user
      state.lineAccessToken = data.access_token
      state.lineRefreshToken = data.refresh_token
      state.tokenExpiration = data.expires_in
    },
    onSignedOutFromLine(state) {
      state.lineUser = null
      state.lineAccessToken = null
      state.lineRefreshToken = null
      state.tokenExpiration = null
    },
    onHandleNameUpdated(state, handleName) {
      state.handleName = handleName
    }
  },
  modules: {},
}

<template>
  <v-app style="background-color: #ddfde1">
    <v-app-bar clipped-left app color="white">
      <v-app-bar-nav-icon @click="drawer=!drawer"></v-app-bar-nav-icon>
      <v-img
        class="mx-5"
        :src="require('@/assets/rs_logo.png')"
        max-height="40"
        max-width="40"
        contain
        @click="$router.push({path: 'map' }).catch(() => {})"
        style="cursor: pointer"
      ></v-img>
      <div
        style="color: #0B3B2E; font-weight: 900; font-size: 2.2em; cursor: pointer"
        @click="$router.push({path: 'map' }).catch(() => {})"
      >
        Rice SCOUTER
      </div>
      <v-spacer></v-spacer>
      <v-btn
          color="pink"
          dark
          v-if="isActive()"
          @click="showLogoutModal = true"
        >
          ログアウト
      </v-btn>
      <v-avatar
        v-if="avatarUrl != null"
        size="42"
        @click="$router.push({path: '/account'}).catch(() => {})"
        style="cursor: pointer"
      >
        <img
          :src="avatarUrl"
          alt=""
        >
      </v-avatar>
      <v-avatar
        v-else
        size="42"
        @click="$router.push({path: '/account'}).catch(() => {})"
        style="cursor: pointer"
      >
        <v-icon dark>
          mdi-account-circle
        </v-icon>
      </v-avatar>

      <div v-if="isActive()" style="margin: 2em">{{ this.lastName }} {{ this.firstName }}</div>
    </v-app-bar>
    <v-navigation-drawer
      app
      clipped
      dark
      v-model="drawer"
      color="#0B3B2E"
    >
      <v-list nav dense>
        <v-list-item
          v-for="(item, i) in drawerItems"
          :key="i"
          @click="$router.push({path: item.path}).catch(() => {})"
        >
          <v-list-item-title>
            <div class="drawer-menu-title">{{ item.name }}</div>
          </v-list-item-title>
        </v-list-item>
      </v-list>

    </v-navigation-drawer>

    <router-view></router-view>

    <!-- loading -->
    <v-overlay :value="loading">
      <v-progress-circular
        indeterminate
      ></v-progress-circular>
    </v-overlay>

    <!-- logout -->
    <v-dialog v-model="showLogoutModal" width="500">
      <v-card>
        <v-container>
          <v-container height="300"></v-container>
          <v-row justify="center" class="text-h5">
            ログアウト
          </v-row>
          <v-row justify="center" class="mt-5 mx-5">
            ログアウトしてよろしいですか？
          </v-row>
          <v-row class="mt-5" justify="center">
            <v-btn
              x-large color='pink' text class="mx-5"
              @click="logout()"
            >
              実行
            </v-btn>
          </v-row>
          <v-container height="200"></v-container>
        </v-container>
      </v-card>
    </v-dialog>

  </v-app>
</template>

<script>
// import axios from "axios";

export default {
  name: 'App',

  components: {
  },

  data: () => ({
    drawer: true,
    loading: false,
    showLogoutModal: false,
    drawerItems: [
      {name: "ホーム", path: "/home"},
      {name: "地図から探す", path: "/map"},
      {name: "表から探す", path: "/table"},
      {name: "プライバシーポリシー", path: "/privacy_policy"},
      {name: "使い方", path: "/how_to_use"},
      
    ]
  }),
  computed: {
    avatarUrl() {
      if (this.$store.state.auth.lineUser != null) {
        return this.$store.state.auth.lineUser.picture
      } else {
        return null
      }
    }
  },
  methods: {
    isAdmin() {
      return this.$store.getters.isAdmin
    },
    isActive() {
      return this.$store.getters.isActive
    },
    logout() {
      this.loading = true
      this.showLogoutModal = false
      this.$store.dispatch('logout')
      this.loading = false
      this.$router.push({path: '/login'})
    }
  },
  created() {
  },
  mounted() {
  }
};
</script>

<style scoped>
.drawer-menu-title {
  font-weight: bold;
  font-size: 1.25em;
}
</style>

